// import React from "react";
// import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch } from "react-router-dom";

// import "assets/scss/material-kit-react.scss?v=1.7.0";

// // pages for this product
// import Components from "views/Components/Components.jsx";
// import LandingPage from "views/LandingPage/LandingPage.jsx";
// import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
// import LoginPage from "views/LoginPage/LoginPage.jsx";

// var hist = createBrowserHistory();

// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/landing-page" component={LandingPage} />
//       <Route path="/profile-page" component={ProfilePage} />
//       <Route path="/login-page" component={LoginPage} />
//       <Route path="/" component={Components} />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );




import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import 'typeface-roboto';
import 'typeface-roboto-slab';
import 'material-design-icons/iconfont/MaterialIcons-Regular.woff';
import 'material-design-icons/iconfont/material-icons.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';

const FallbackApp = props => (
    <div>
        <h1>No app configured</h1>
        <h2>Available apps:</h2>
        <ul>
            <li><code>courses</code></li>
        </ul>
        <p>Please set the environment variable REACT_APP_ROOT and restart...</p>
    </div>
)

console.log('env vars', process.env)

const App = React.lazy(() => {
    let appRoot = process.env.REACT_APP_ROOT;
    console.log('Starting React App', appRoot);

    switch(appRoot) {
        case 'courses':
            return import('./courses/CoursesApp');
        default:
            console.log('... unknown REACT_APP_ROOT', appRoot, ' serving ./App instead')
            return Promise.resolve({default: FallbackApp});
    }
});

ReactDOM.render((
    <React.Suspense fallback={<div>loading...</div>}>
        <App />
    </React.Suspense>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
